<template lang="">
    <div class="w-100 h-100">
        <div class="application-confirmation">
            <div>
                <img :src="applicationImage" alt="applicationImage"/>
            </div>
            <div class="application-confirmation-title">
                {{ titleComputed }}
            </div>
            <span class="my-4">
                <i v-if="failed" class="icon-5x far fa-times-circle text-danger"></i>
                <i v-else class="icon-5x far fa-check-circle text-success"></i>
            </span>
            <i18n
                path="APPLICATION.PAYMENT_REDIRECT_TEXT"
                tag="p"
                class="application-confirmation-text"
            >

                <b-link :disabled="!canRedirect" @click="redirectTo" class="font-weight-bold">
                    {{ $t('GENERAL.CLICK_HERE') }}
                </b-link>
            </i18n>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ApplicationPaymentNotify",
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            applicationImage: `${process.env.BASE_URL}media/st-application-registred.png`,
            failed: false,
            canRedirect: false,
        };
    },
    computed: {
        ...mapGetters({
            paymentStatus: 'applications/form/paymentStatus',
        }),
        titleComputed() {
            if (this.failed) {
                return this.$t('APPLICATION.PAYMENT_FAILED_TITLE');
            } else {
                return this.$t('APPLICATION.PAYMENT_SUCCESS_TITLE');
            }
        },
    },
    methods: {
        ...mapActions({
            checkPaymentStatus: 'applications/form/checkPaymentStatus',
        }),
        redirectTo() {
            this.$router.replace({ name: 'applicationEdit', params: { id: this.id }, query: { state: 'redirect' } });
        },
        timeoutRedirect() {
            this.canRedirect = true;
            this.timeoutId = setTimeout(() => {
                this.redirectTo();
            }, 3000);
        }
    },
    watch: {
        paymentStatus: function(status) {
            if (status !== 'created') {
                clearInterval(this.polling);
                this.timeoutRedirect();
            }
        },
    },
    created() {
        this.failed = this.$route?.query?.failed === 'true';
        if (this.failed) {
            this.timeoutRedirect();
        } else {
            this.polling = setInterval(() => {
                this.checkPaymentStatus(this.id);
            }, 1000);
        }
    },
    beforeDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = null;
        if (this.polling) {
            clearTimeout(this.polling);
        }
        this.polling = null;
    },
};
</script>
<style lang="">
</style>
